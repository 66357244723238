// packages
import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
// import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import jwt_decode from 'jwt-decode';
import axios from 'axios';
// import { CssBaseline } from '@material-ui/core';
import { history } from './vibe/components/utilities/MainUtils/hist';
// routs
import DashboardLayout from './layouts/DashboardLayout';
// components
// import LoginPage from './views/pages/LoginPage/LoginPage';
import Component404 from './views/pages/404';
import ErrorFizicalPerson from './views/pages/ErrorFizicalPerson';
import InfoUrPerson from './views/pages/InfoUrPerson';
import QuestionPerson from './views/pages/QuestionPerson';
import QuestionFizPerson from './views/pages/QuestionFizPerson';
import Analytic from './views/pages/Analytic';
import PrivateRoute from './PrivateRoute';
// style
import './vibe/scss/styles.scss';
import './customStyles/datePicker.css';
import './customStyles/uploader.css';
import '../node_modules/@sweetalert2/theme-material-ui/material-ui.scss';
// redux
import configureStore from './redux/configureStore';

import LandingPage from './views/pages/LandingPage/LandingPage';
import RegisterRecipientPublic from './views/pages/Public/RegisterRecipientsPublic';
import RecipientInfo from './views/pages/Public/RecipientInfo';
import RegisterPublic from './views/pages/Public/RegisterPublic';
import CargoInfo from './views/pages/Public/PublicCargo/CargoInfo';
import Commission from './views/pages/Public/Commission';
// redux
import setAuthToken from './vibe/components/utilities/MainUtils/setAuthToken';
import isEmpty from './vibe/components/utilities/MainUtils/is-empty';
import { setCurrentUser, logoutUser } from './redux/actions/auth.action';
import { setCatalogs } from './redux/actions/catalog.action';
import { getRegion, country, aidForm, meetingState } from './api/api';
import AdminPage from './views/pages/Admin/AdminPage';
import MvsPage from "./views/pages/Mvs/MvsPage";

const store = configureStore();

const theme = createTheme({
  typography: {
    fontFamily: [
      'dia',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    primary: {
      main: '#141414',
    },
    secondary: {
      main: '#79ac3d',
    },
    text: {
      primary: '#141414',
      secondary: 'rgba(20, 20, 20, 0.6)',
    },
  },
  overrides: {
    MuiModal: {
      root: {
        zIndex: '1300 !important',
      },
    },
    MuiTextField: {
      root: {
        minWidth: '100%',
      },
    },
    MuiTypography: {
      root: {
        color: '#141414',
      },
      subtitle1: {
        fontSize: '24px',
      },
      subtitle2: {
        fontSize: '20px',
      },
      listDescription: {
        fontSize: '16px',
      },
      body2: {
        fontSize: '14px',
      },
    },
    MuiIcon: {
      root: {
        fontSize: '14px',
      },
    },
    MuiButton: {
      root: {
        borderRadius: '30px',
        fontSize: 16,
        fontWeight: 600,
        padding: '18px 36px',
        '&:active': {
          boxShadow: 'none',
          backgroundColor: '#0062cc',
          borderColor: '#005cbf',
        },
        '&:focus': {
          boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        },
        '&:disabled': {
          color: '#fff',
          backgroundColor: 'grey',
        },
      },
      text: {
        padding: '18px 36px',
        fontSize: 16,
      },
      containedPrimary: {
        background: '#141414',
        boxShadow: 'none',
        color: '#fff',
        height: 60,
        border: '2px solid transparent',
        '&:hover': {
          backgroundColor: 'rgba(242,242,242, 0)',
          border: '2px solid rgb(0,0,0)',

          boxShadow: 'none',
          color: '#141414',
        },
      },
      outlined: {
        border: '2px solid #141414',
        fontWeight: 600,
      },
      outlinedPrimary: {
        color: '#141414',
        border: '1px solid #1414140',
        padding: '18px 36px',
        height: 60,
        '&:hover': {
          background: 'linear-gradient(to bottom right, #000, #333)',
          boxShadow: 'none',
          color: '#fff',
          border: '2px solid transparent',
        },
      },
    },
    MuiPaper: {
      root: {
        borderRadius: '0px !important',
      },
    },
    MuiCard: {
      root: {
        overflow: 'visible',
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        '&:focus': {
          opacity: 1,
          outline: 'none',
          border: 'none',
        },
      },
    },
    MuiAutocomplete: {
      popupIndicator: {
        marginRight: 16,
      },
    },
  },
});

if (localStorage.catalogs) {
  const catalogs = JSON.parse(localStorage.catalogs);
  store.dispatch(setCatalogs(catalogs));
}

async function getMeetingState() {
  try {
    const response = await meetingState();
    localStorage.setItem('meetingState', JSON.stringify(response.data));
  } catch (error) {
    localStorage.setItem('meetingState', []);
  }
}

if (!localStorage.meetingState) {
  getMeetingState();
}

async function getUkraineRegion() {
  try {
    const response = await getRegion();
    localStorage.setItem('region', JSON.stringify(response.data));
  } catch (error) {
    localStorage.setItem('region', []);
  }
}

if (!localStorage.region) {
  getUkraineRegion();
}
async function getReference() {
  try {
    const [response, success] = await axios.all([country(), aidForm()]);
    const reference = {
      countries: response.data,
      aidForm: success.data,
    };
    localStorage.setItem('reference', JSON.stringify(reference));
  } catch (error) {
    localStorage.setItem('reference', []);
  }
}

if (!localStorage.reference) {
  getReference();
}

if (localStorage.jwtToken) {
  setAuthToken(localStorage.jwtToken);
  // Decode token and get user info and exp
  const decoded = jwt_decode(localStorage.jwtToken);

  const userInfo = {
    sub: JSON.parse(decoded.sub),
    menu: JSON.parse(decoded.menu),
  };

  // Set user and isAuthenticated
  if (!isEmpty(userInfo.sub)) {
    store.dispatch(setCurrentUser(userInfo));
  }

  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
    // Redirect to login
    // window.location.href = '/login';
    history.push('/');
  }
}

export default function App() {
  return (
    <Provider store={store}>
      {/* <CssBaseline /> */}
      <MuiThemeProvider theme={theme}>
        <Router history={history}>
          <Switch>
            <Route exact path="/" component={LandingPage} />
            <Route path="/register-recipients-public" component={RegisterRecipientPublic} />
            <Route path="/admin" component={AdminPage} />
            <Route path="/mvs" component={MvsPage} />
            <Route path="/recipient-info/:id/view" component={RecipientInfo} />
            {/* <Route path="/register-public" component={RegisterPublic} /> */}
            {/* <Route path="/cargo-info/:id/view" component={CargoInfo} /> */}
            {/* <Route path="/commission" component={Commission} /> */}
            {/* <Route path="/login-page" component={LoginPage} /> */}
            <Route path="/error-page" component={ErrorFizicalPerson} />
            <Route path="/info-page" component={InfoUrPerson} />
            <Route path="/question-page" component={QuestionPerson} />
            <Route path="/question-fiz-page" component={QuestionFizPerson} />
            <Route path="/analytics" component={Analytic} />
            <PrivateRoute component={DashboardLayout} />
            {/* <Route component={DashboardLayout} /> */}
            <Route component={Component404} />
          </Switch>
        </Router>
      </MuiThemeProvider>
    </Provider>
  );
}
