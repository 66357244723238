/*
  Regular expression rules for final validation.

  Using:
  import rules from "./rules";
  var isCorrect = rules.name.test("Щербатюк'");

*/

const rules = {
  name: /^[А-ЕЖ-ЩЬЮЯЇІЄҐ'-]*$/, // name/firstname/fatherName
  // name: /^[А-ЕЖ-ЩЬЮЯЇІЄҐа-еж-щьюяїієґ'-]*$/, // name/firstname/fatherName
  nameEng: /^[А-ЕЖ-ЩЬЮЯЇІЄҐA-Z'-]*$/, // name/firstname/fatherName (with English)
  edrpou: /^[0-9]*$/,
  edrpouInput: /^[0-9]{0,8}$/,
  number: /^[\d{8}]*$/,
  numberInv: /^\d+\.?\d{0,2}$/,
  plate: /^[0-9A-Z]*$/,
  // orgName :/^$|^[0-9А-ЕЖ-ЩЬЮЯЇІЄҐ'" -]+$/,
  orgName: /^[0-9А-ЕЖ-ЩЬЮЯЇІЄҐ№,.+-:  '\"/\\ \- ()]*$/,
  address: /^[0-9А-ЕЖ-ЩЬЮЯЇІЄҐ№,.'/\\ \"-]*$/,
  email: /^[a-zA-Z0-9 . @ _ -]*$/,
  occupation: /^[0-9А-ЕЖ-ЩЬЮЯЇІЄҐ№,.  '\"/\\ \- ()]*$/,
  localization: /^[0-9А-ЕЖ-ЩЬЮЯЇІЄҐ№A-Z,.'/\\ \"-]*$/,
  vin: /^[a-zA-Z0-9]*$/,
};

export default rules;
